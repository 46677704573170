var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
var servicesSlider = new Swiper('.services-slider .swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    breakpoints: {
      430: {
        slidesPerView: 1.1,
      },
      768: {
          slidesPerView: 2,
      },
      1024: {
          slidesPerView: 2.93,
          spaceBetween: 30,
      },
    },
});
