FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

jQuery(document).ready(function($) {

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== INIT FUNCSTIONS ======================  */

const svgIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="13.597" height="6.72" viewBox="0 0 13.597 6.72">
  <path id="Path_1574" data-name="Path 1574" d="M43.572,6.75l4.314,5.393-4.314,5.393" transform="translate(18.941 -42.167) rotate(90)" fill="none" stroke="#273d51" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
</svg>
`;

const menuItems = document.querySelectorAll('.menu-item-has-children > a');

menuItems.forEach(item => {
  item.innerHTML += svgIcon;
});

/* ====================== HEADER ======================  */

$('.header__mobile__btn').on('click', function() {
  $('.header').toggleClass('active');
  $('.nav').toggleClass('nav--open');
  $('body').toggleClass('noscroll');

  if ($('.nav--open').length) {
    $(document).on('click', '.menu-item-has-children', function() {
      //slide down the child sub menu
      console.log('test');
      $(this).find('.sub-menu').slideToggle();
    });
  } else {
    $(document).off('click', '.menu-item-has-children');
  }
});

// when the input inside of .contact-form__form__input > span > input is focused on, add the class .active to the label element
// when another input is focused on, remove the class .active from the label element
// if the input has text in it, the active class on the label should stick

$('.contact-form__form__input > span > input').on('focus', function() {
  $(this).parent().siblings('label').addClass('active');
});

$('.contact-form__form__input > span > input').on('focusout', function() {
  if ($(this).val().trim() === '') {
    $(this).parent().siblings('label').removeClass('active');
  }
});

$('.contact-form__form__input > span > textarea').on('focus', function() {
  $(this).parent().siblings('label').addClass('active');
});

$('.contact-form__form__input > span > textarea').on('focusout', function() {
  if ($(this).val().trim() === '') {
    $(this).parent().siblings('label').removeClass('active');
  }
});

/* ====================== WINDOW REISZE FUNCTION  ======================  */
  $(window).resize(function() {
  })
});
